import { parseQueryStrings } from '@crehana/utils';

export const getNextUrl = (): string => {
  const nextParam = parseQueryStrings(window.location.search)?.next;
  const nextUrl = typeof nextParam === 'string' ? nextParam : '';

  return nextUrl;
};

export const redirectWithQueryParams = (url: string) => {
  const queryParams = window.location.search;
  const nextUrl = getNextUrl();

  /**
   * We need to remove the `next` query param if it contains /registro-extra/
   * when we change to /entrar/ so we don't trigger the onboarding after login,
   * all of this avoiding loosing other query params like utms
   */
  if (url === '/entrar/' && nextUrl.includes('registro-extra')) {
    const qs = parseQueryStrings(window.location.search);

    if (qs) {
      delete qs.next;

      const search = Object.keys(qs).reduce(
        (acc, key) => `${acc}${acc ? '&' : ''}${key}=${qs[key]}`,
        '',
      );

      window.location.href = `${url}${search ? `?${search}` : ''}`;
      return;
    }
  }

  window.location.href = `${url}${queryParams}`;
};

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const sendRegisterTrackToGoogleAdsAndAnalytics = () => {
  // Google tag manager
  if (window.gtag) {
    window.gtag('event', 'conversion', {
      send_to: 'AW-769894422/7wKWCJ_UkNwBEJbQju8C',
    });
    window.gtag('event', 'Registro', {
      event_category: 'Registro',
      event_label: 'Registro Usuario',
      send_to: 'UA-60829200-1',
    });
  }
};
