import * as React from 'react';

import { useTranslation } from '@crehana/i18n';
import FacebookLogo from '@crehana/icons/FacebookLogo';
import GoogleIcon from '@crehana/icons/GoogleIcon';
import useIsClient from '@crehana/react-hooks/dist/useIsClient';

import { FormView } from '../../types';
import LoadingOverlay from '../LoadingOverlay';
import { useSocialAuth, UseSocialAuthOptions } from './react-hooks';

type SocialButtonsV2 = {
  nextUrl?: string;
  referFriendId?: number;
  getNextUrl?: () => string;
  onSuccess?: UseSocialAuthOptions['onSuccess'];
  isDark?: boolean;
  formView: FormView;
};

const SocialButtonsV2: React.FC<React.PropsWithChildren<SocialButtonsV2>> = ({
  nextUrl,
  getNextUrl,
  onSuccess,
  referFriendId,
  isDark,
  formView,
}) => {
  const { t } = useTranslation();
  const isClient = useIsClient();
  const {
    gmailButtonProps,
    facebookButtonProps,
    setupAndInitSocialAuthScripts,
    authState: { processingSocialAuth, loadingFacebookAndGoogleScripts },
  } = useSocialAuth({
    nextUrl,
    referFriendId: String(referFriendId),
    getNextUrl,
    onSuccess,
  });

  const isRegister = formView === 'register';

  React.useEffect(() => {
    setupAndInitSocialAuthScripts();
  }, []);

  return (
    <div className="relative">
      <button
        type="button"
        className="w-full h-56 flex items-center px-32 rounded-5"
        style={{
          backgroundColor: '#1877F2',
        }}
        {...facebookButtonProps}
        disabled={processingSocialAuth || loadingFacebookAndGoogleScripts}
      >
        <FacebookLogo color="white" size={26} />{' '}
        <span className="text-white font-link block ml-24">
          {isRegister
            ? t('LOGIN_WITH_FACEBOOK_SING_UP', {
                defaultValue: 'Continúa con Facebook',
              })
            : t('LOGIN_WITH_FACEBOOK_SIGN_IN', {
                defaultValue: 'Continúa con Facebook',
              })}
        </span>
      </button>
      {/* the gmail will only be rendered on the client because we need to evaluate
      if the current browser is a web view or not
      google block the login flow inside webviews */}
      {isClient && (
        <button
          type="button"
          className="w-full h-56 flex items-center bg-white mt-8 mb-32 px-32 rounded-5 border border-solid border-secondary-light-56"
          disabled={processingSocialAuth || loadingFacebookAndGoogleScripts}
          style={{
            border: isDark ? '' : '1px solid rgba(0, 153, 255, 0.3)',
            display: window.navigator.userAgent
              .toLowerCase()
              .includes('instagram')
              ? 'none'
              : 'flex',
          }}
          {...gmailButtonProps}
        >
          <GoogleIcon size={26} />{' '}
          <span className="text-base-main font-link block ml-24">
            {isRegister
              ? t('LOGIN_WITH_GOOGLE_SING_UP', {
                  defaultValue: 'Continúa con Google',
                })
              : t('LOGIN_WITH_GOOGLE_SIGN_IN', {
                  defaultValue: 'Continúa con Google',
                })}
          </span>
        </button>
      )}
      {(processingSocialAuth || loadingFacebookAndGoogleScripts) && (
        <LoadingOverlay />
      )}
    </div>
  );
};

export default SocialButtonsV2;
