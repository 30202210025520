import { useEffect, useRef, useState } from 'react';

import { useMutation } from '@apollo/client';

import { useAmplitude } from '@crehana/analytics';
import type { MeObject } from '@crehana/auth';
import { customLocalStorage, getNextUrl } from '@crehana/utils';
import { useTrackingContext } from '@crehana/web/ReactContexts/TrackingContext';

import SOCIAL_LOGIN_MUTATION from '../../graphql/AuthFormSocialloginMutation.auth.graphql';
import {
  AuthFormSocialloginMutation,
  AuthFormSocialloginMutationVariables,
} from '../../graphql/types/AuthFormSocialloginMutation';
import { sendRegisterTrackToGoogleAdsAndAnalytics } from '../../utils';
import loadGoogleAndFacebookScripts from './utils';

type SocialLoginVariables = {
  isJwtAuth?: boolean;
  token: string;
  nextUrl?: string;
  backend: string;
  referFriendId?: number;
  friendInvitationId?: string;
};

export type UseSocialAuthOptions = {
  friendInvitationId?: string;
  referFriendId?: string;
  nextUrl?: string;
  onSuccess?: (params: {
    redirect?: string | null;
    user?: MeObject;
    isNewUser?: boolean | null;
    graphqlResponse?: AuthFormSocialloginMutation['socialLogin'];
  }) => void;
  onFinishToLoadScripts?: (success: boolean) => void;
  /**
   * Pass the page, section or component where this component is used.
   * This prop is used to send extra data to the Amplitude
   */
  isRenderedIn?: string;
  getNextUrl?: () => string | undefined;
  isJwtAuth?: boolean;
  hideFacebookLink?: boolean;
};

export const googleFallbackUrl = '/login/google-oauth2/';
export const facebookFallbackUrl = '/login/facebook/';

let idCounter = 0;
const genId = () => {
  idCounter += 1;
  return idCounter;
};

export const useSocialAuth = ({
  friendInvitationId,
  referFriendId,
  nextUrl: nextUrlProp,
  onSuccess,
  onFinishToLoadScripts,
  getNextUrl: getNextUrlProp,
  isJwtAuth,
  hideFacebookLink,
}: UseSocialAuthOptions = {}) => {
  const { logEvent } = useAmplitude();
  const { formattedIntent, clearIntent } = useTrackingContext();
  const gmailButtonRef = useRef<HTMLButtonElement>(null);
  const gmailHTMLButtonId = useRef(`customGoogleButton-${genId()}`);
  const [processingSocialAuth, setProcessingSocialAuth] = useState(false);
  const [socialLogin] = useMutation<
    AuthFormSocialloginMutation,
    AuthFormSocialloginMutationVariables
  >(SOCIAL_LOGIN_MUTATION, { context: { clientName: 'auth' } });

  const googleAuthRef = useRef<gapi.auth2.GoogleAuth>();

  const [state, setState] = useState({
    loadingFacebookAndGoogleScripts: false,
    errorToLoadFacebookAndGoogleScripts: false,
  });

  useEffect(() => {
    // We clear the "Intent (Job to be done) data" when the component gets unmounted
    return clearIntent;
  }, []);

  const _getNextUrl = (): string | undefined => {
    if (getNextUrlProp) {
      return getNextUrlProp();
    }

    return nextUrlProp || getNextUrl();
  };

  const nextUrl = _getNextUrl();

  const login = async (variables: SocialLoginVariables) =>
    socialLogin({
      variables: {
        isJwtAuth,
        token: variables.token,
        nextUrl: variables.nextUrl,
        backend: variables.backend,
        referFriendId,
        friendInvitationId,
        // JobToBeDone data
        ...formattedIntent,
      },
    });

  const redirectToFacebookLoginPage = () => {
    window.location.href = `${facebookFallbackUrl}?next=${nextUrl}`;
  };

  const redirectToGoogleLoginPage = () => {
    window.location.href = `${googleFallbackUrl}?next=${nextUrl}`;
  };

  const attachSignin = (element: HTMLButtonElement | null) => {
    if (!element || !googleAuthRef.current) {
      return;
    }

    googleAuthRef.current.attachClickHandler(
      element,
      {},
      googleUser => {
        setProcessingSocialAuth(true);
        login({
          token: googleUser.getAuthResponse(true).access_token,
          nextUrl,
          backend: 'google-oauth2',
          friendInvitationId,
        }).then(response => {
          setProcessingSocialAuth(false);
          if (response?.data?.socialLogin?.success) {
            // TODO: talk with the code owner to fix this code so we can enable this rule again
            // eslint-disable-next-line no-unsafe-optional-chaining
            const { redirect, isNewUser } = response?.data?.socialLogin;

            if (isNewUser) {
              sendRegisterTrackToGoogleAdsAndAnalytics();
            } else {
              logEvent({
                event: 'Successful SignIn',
                data: {
                  source: 'Google',
                },
              });
            }

            if (onSuccess) {
              onSuccess({
                redirect,
                isNewUser,
                graphqlResponse: response?.data?.socialLogin,
              });
            } else {
              setTimeout(() => {
                window.location.href = redirect || '/';
              }, 0);
            }
          } else {
            redirectToGoogleLoginPage();
          }
        });
      },
      error => {
        console.log('Google error auth', error);
        redirectToGoogleLoginPage();
      },
    );
  };

  const initializeGoogle = () => {
    if (
      process.env.NODE_ENV === 'production' &&
      window.location.origin.includes('crehana.com')
    )
      window.gapi.load('auth2', () => {
        // Retrieve the singleton for the GoogleAuth library and set up the client.

        googleAuthRef.current = window.gapi.auth2.init({
          client_id: process.env
            .NEXT_PUBLIC_SOCIAL_AUTH_GOOGLE_OAUTH2_KEY as string,
          cookie_policy: 'single_host_origin',
          // Request scopes in addition to 'profile' and 'email'
          // scope: 'additional_scope'
        });
        attachSignin(gmailButtonRef.current);
      });
  };

  const loginWithFacebook = () => {
    // RemoveConditionThatPreventsContinueWithFacebookFloatingButtonToBeShown
    customLocalStorage.removeItem('CREH__continueAsFacebookUserDialog');

    if (hideFacebookLink) {
      redirectToFacebookLoginPage();
      return;
    }

    if (typeof window !== 'undefined' && window.FB) {
      window.FB.getLoginStatus(facebookLoginStatus => {
        const backend = 'facebook';

        if (facebookLoginStatus.status === 'connected') {
          const token = facebookLoginStatus.authResponse.accessToken;

          setProcessingSocialAuth(true);

          login({
            token,
            nextUrl,
            backend,
            friendInvitationId,
          }).then(response => {
            setProcessingSocialAuth(false);
            if (response?.data?.socialLogin?.success) {
              const { redirect, isNewUser } = response.data.socialLogin;

              if (isNewUser) {
                sendRegisterTrackToGoogleAdsAndAnalytics();
              } else {
                logEvent({
                  event: 'Successful SignIn',
                  data: {
                    source: 'Facebook',
                  },
                });
              }

              if (onSuccess) {
                onSuccess({
                  redirect,
                  graphqlResponse: response.data.socialLogin,
                });
              } else {
                window.location.href = redirect || '/';
              }
            } else {
              redirectToFacebookLoginPage();
            }
          });
        } else {
          window.FB.login(
            loginResponse => {
              if (loginResponse.authResponse) {
                const token = loginResponse.authResponse.accessToken;

                setProcessingSocialAuth(true);
                login({
                  token,
                  nextUrl,
                  backend,
                  friendInvitationId,
                }).then(response => {
                  setProcessingSocialAuth(false);
                  if (response?.data?.socialLogin?.success) {
                    const { redirect, isNewUser } = response.data.socialLogin;

                    if (isNewUser) {
                      sendRegisterTrackToGoogleAdsAndAnalytics();
                    } else {
                      logEvent({
                        event: 'Successful SignIn',
                        data: {
                          source: 'Facebook',
                        },
                      });
                    }

                    if (onSuccess) {
                      onSuccess({ redirect, isNewUser });
                    } else {
                      setTimeout(() => {
                        window.location.href = redirect || '/';
                      }, 10);
                    }
                  } else {
                    redirectToFacebookLoginPage();
                  }
                });
              } else {
                redirectToFacebookLoginPage();
              }
            },
            { scope: 'email' },
          );
        }
      });
    }
  };

  function setupAndInitSocialAuthScripts() {
    setState({
      ...state,
      loadingFacebookAndGoogleScripts: true,
    });
    loadGoogleAndFacebookScripts()
      .then(() => {
        if (onFinishToLoadScripts) {
          onFinishToLoadScripts(true);
        }

        setState(prevState => ({
          ...prevState,
          errorToLoadFacebookAndGoogleScripts: false,
          loadingFacebookAndGoogleScripts: false,
        }));
        initializeGoogle();
      })
      .catch(error => {
        console.error('Errot to load the facebook and google skd', error);
        if (onFinishToLoadScripts) {
          onFinishToLoadScripts(false);
        }
        setState(prevState => ({
          ...prevState,
          errorToLoadFacebookAndGoogleScripts: true,
          loadingFacebookAndGoogleScripts: false,
        }));
      });
  }

  return {
    setupAndInitSocialAuthScripts,
    authState: {
      ...state,
      processingSocialAuth,
    },
    gmailButtonProps: {
      id: gmailHTMLButtonId.current,
      ref: gmailButtonRef,
    },
    facebookButtonProps: {
      onClick: loginWithFacebook,
    },
  };
};
