import Loader from '@crehana/ui/dist/Loader';

const LoadingOverlay = () => {
  return (
    <div className="absolute w-full h-full top-0 left-0 z-dialog flex justify-center items-center bg-base-main-80">
      <Loader size={48} white />
    </div>
  );
};

export default LoadingOverlay;
