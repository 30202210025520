import { loadScript } from '@crehana/utils';

const loadGoogleAndFacebookScripts = () => {
  const scripts = [
    // @ts-ignore
    import(/* webpackChunkName: "facebook-sdk" */ './facebookSdk'),
  ];

  if (!window.gapi) {
    scripts.push(
      loadScript({ src: 'https://apis.google.com/js/api:client.js' }),
    );
  }

  return Promise.all(scripts);
};

export default loadGoogleAndFacebookScripts;
